import { Pane } from 'evergreen-ui';

const Container = (props: any) => {

	return (
		<Pane
			marginX="auto"
			width={600}
			{...props}
		>
			{props.children}
		</Pane>
	);

};

export default Container;