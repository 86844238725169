import React from 'react';
import { Pane } from 'evergreen-ui';

/* Logos */
import LogoPrideImage from './../images/logo-pride-diagonal.png';

const Logo = () => {

	return (
		<Pane className="logo" marginY={90}>
			<img src={LogoPrideImage} alt="Logo" height={60} />
		</Pane>
	);

};

export default Logo;