import React from "react";
import { Avatar, Pane } from "evergreen-ui";

const Avatars = () => {

	return (
		<Pane marginTop={30}>
			<a href="https://github.com/vallieres13" target="_blank" rel="noreferrer">
				<Avatar
					src="https://avatars.githubusercontent.com/u/48228035?v=4"
					name="Felix Hebgen"
					size={64}
					marginX={8}
					/>
			</a>
			<a href="https://github.com/PPrzemko" target="_blank" rel="noreferrer">
				<Avatar
					src=""
					name="L. S."
					size={64}
					marginX={8}
					/>
			</a>
			<a href="#!">
				<Avatar
					src=""
					name="S. P."
					size={64}
					marginX={8}
					/>
			</a>
			<a href="#!">
				<Avatar
					name="A. D."
					size={64}
					marginX={8}
					/>
			</a>
		</Pane>
	);

};

export default Avatars;