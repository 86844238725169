import React from 'react';
import { Pane, Heading, Paragraph, Link } from 'evergreen-ui';

/* Components */
import Avatars from './Avatars';

const Content = () => {
	return (
		<Pane>
			<Heading size={900} marginTop={32} marginBottom={16} fontFamily="Monument Regular" textTransform="lowercase">
				Who we are
			</Heading>
			<Paragraph size={400} margin={19}>
				visualstatic &copy; is a German software development team creating commercial software for production
				and enterprise customers. We specialise in web applications using modern-day client frameworks such as
				React.js and server-side APIs in php and Kotlin in Java EE. Leave us a message at <Link href="mailto:hello@visualstatic.net" color="neutral" fontWeight="bold">development@visualstatic.net</Link> if you would like to get in touch.
			</Paragraph>
			<Avatars />
		</Pane>
	);
};

export default Content;