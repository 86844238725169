import React from 'react';

/* Stylesheets */
import './App.css';

/* Components */
import Container from './components/Container';
import Logo from './components/Logo';
import Content from './components/Content';
import Copyright from './components/Copyright';

function App() {
  return (
    <div className="app">
        <Logo />
        <Container>
            <Content />
        </Container>
        <Copyright />
    </div>
  );
}

export default App;
