import { Heading } from "evergreen-ui";
import React from "react";

const Copyright = () => {

	return (
		<Heading size={100} marginTop={16} className="copyright">
			2012 &mdash; 2021 &copy; visualstatic Germany
		</Heading>
	);

}

export default Copyright;